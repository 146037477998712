export default {
  dataModel: {
    types: ['apods:PodProvider'],
    list: {
      containers: {
        activitypods: ['/pod-providers']
      },
      fetchContainer: true
    }
  }
};
